import { CSSProperties } from 'react';
import { OptionsMode } from 'forms/AddProductForm/OptionGroup';
import { OrderResponsePaymentInfo, OrderResponseProduct } from 'state/order/types';

export enum OrderStatus {
    UNPAID = 'UNPAID',
    NEW = 'NEW',
    WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
    PREPARING = 'PREPARING',
    DELIVERY = 'DELIVERY',
    CLOSED = 'CLOSED',
}

export enum UnitType {
    GR = 'GR',
    KG = 'KG',
    ITEM = 'ITEM',
    M = 'M',
    M2 = 'M2',
    M3 = 'M3',
    RM = 'RM',
}

export enum SupportedCountryCodes {
    RUS = 'RUS',
    ITA = 'ITA',
    USA = 'USA',
    GBR = 'GBR',
    NLD = 'NLD',
    SRB = 'SRB',
    UZB = 'UZB',
}

export enum CountryToLocaleMap {
    RUS = 'ru',
    ITA = 'it',
    USA = 'en',
    GBR = 'en',
    NLD = 'nl',
    SRB = 'rs',
    UZB = 'uz',
}

export const Iso2To3Map: Record<string, SupportedCountryCodes> = {
    RU: SupportedCountryCodes.RUS,
    US: SupportedCountryCodes.USA,
    GB: SupportedCountryCodes.GBR,
    NL: SupportedCountryCodes.NLD,
    UZ: SupportedCountryCodes.UZB,
    SR: SupportedCountryCodes.SRB,
    IT: SupportedCountryCodes.ITA,
};
export const Iso3To2Map: Record<SupportedCountryCodes, string> = {
    [SupportedCountryCodes.RUS]: 'RU',
    [SupportedCountryCodes.USA]: 'US',
    [SupportedCountryCodes.GBR]: 'GB',
    [SupportedCountryCodes.NLD]: 'NL',
    [SupportedCountryCodes.UZB]: 'UZ',
    [SupportedCountryCodes.SRB]: 'SR',
    [SupportedCountryCodes.ITA]: 'IT',
};

export enum DeliveryType {
    STORE_DELIVERY = 'STORE_DELIVERY',
    PICKUP = 'PICKUP',
    DELIVERY_SERVICE = 'DELIVERY_SERVICE',
    SPECIFIC_DELIVERY = 'SPECIFIC_DELIVERY',
}

export enum PaymentType {
    CASH = 'CASH',
    CARD = 'CARD',
    OLPAY = 'OLPAY', //online payment
}

export enum UserRole {
    VENDOR = 'VENDOR',
    CUSTOMER = 'CUSTOMER',
    ADMINISTRATOR = 'ADMINISTRATOR',
    OPERATION_ADMIN = 'OPERATION_ADMIN',
}

export enum ImageType {
    CATEGORY = 'CATEGORY',
    PRODUCT = 'PRODUCT',
    STORE = 'STORE',
    STORE_COVER = 'STORE_COVER',
}

export enum VendorContactType {
    PHONE = 'PHONE',
    SITE = 'SITE',
    INSTA = 'INSTA',
    FB = 'FB',
    VK = 'VK',
    WA = 'WA',
}

export enum DayOfWeek {
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
    SUNDAY = 'SUNDAY',
}

export enum StoreCategory {
    PRODUCTS = 'PRODUCTS',
    RESTAURANTS = 'RESTAURANTS',
    FLOWERS = 'FLOWERS',
    FOR_HOME = 'FOR_HOME',
    FOR_CHILDREN = 'FOR_CHILDREN',
    CLOTHS_SHOES = 'CLOTHS_SHOES',
    PETS = 'PETS',
    BEAUTY_HEALTH = 'BEAUTY_HEALTH',
    SPORTS = 'SPORTS',
    BUILDING = 'BUILDING',
    FURNITURE = 'FURNITURE',
    EDUCATION = 'EDUCATION',
    SERVICES = 'SERVICES',
    OTHER = 'OTHER',
}

export type StoreCategoryFilter = StoreCategory;

export enum LegalDocumentLink {
    AGREEMENT = '/legal-documents/Пользовательское соглашение Сервиса «any.market».pdf',
    AGREEMENT_FOR_VENDORS = '/legal-documents/Публичная оферта Сервиса «any.market» для Продавцов.pdf',
    PERSONAL_DATA_PROCESSING_CONSENT = '/legal-documents/Согласие обработку персональных данных сервисом «any.market».pdf',
    PRIVACY_POLICY = '/legal-documents/Политика конфиденциальности сервиса «any.market».pdf',
    CONTACT_INFO = '/legal-documents/Реквизиты.pdf',
}

export enum StoreCurrentState {
    CARD = 'CARD',
    SIMPLE = 'SIMPLE',
    SELLING = 'SELLING',
    FULL = 'FULL',
}

export enum PromoDiscountType {
    ORDER = 'ORDER',
    PRODUCT = 'PRODUCT',
    // DELIVERY = 'DELIVERY',
}

export enum PromoDeliveryType {
    ANY = 'ANY',
    PICKUP = 'PICKUP',
    DELIVERY = 'DELIVERY',
}

export enum PromoLabel {
    GIFT = 'GIFT',
    PERCENT = 'PERCENT',
}

export enum NotificationType {
    PHONE = 'PHONE',
    EMAIL = 'EMAIL',
}

export interface Time {
    hour: number;
    minute: number;
    second: number;
    nano: number;
}

export interface UserWhoAmI {
    name: string;
    roles: UserRole[];
    userId: string;
    username: string;
}

export interface ImageDto {
    defaultImage: boolean;
    imageId: string;
    type: ImageType;
}

export interface ContactDto {
    contactId: string;
    value: string;
    type: VendorContactType;
    defaultContact: boolean;
}

export interface AddressDto {
    addressId: string;
    city: { name: string; sublocality: string };
    street: { name: string; number: string };
    house: { number: number };
    bulk: { number: number };
    building: { number: number };
    apartment: { number: number };
    geoCoordinates: GeoCoordinatesDto;
    region: string;
    country: string;
    formattedAddress: string;
    isoCode: SupportedCountryCodes;
}

export interface TimeBreakDto {
    start: string;
    end: string;
}

export interface ScheduleDayDto {
    dayOfWeek: DayOfWeek;
    opens: string;
    closes: string;
    timeBreak: TimeBreakDto;
}

export interface GeoCoordinatesDto {
    latitude: number;
    longitude: number;
    qc_geo?: number;
}

export interface StoreDeliveryDataDto {
    hoursToStore: number;
    comment: string;
    price: number;
    minOrderPrice: number;
    maxDistance: number;
    // Optional:
    pricingModel?: PaidDeliveryType;
}

export interface StoreDeliveryDto {
    type: DeliveryType;
    enabled: boolean;
    data: StoreDeliveryDataDto[];
}

export interface StorePaymentDataDto {
    atStore: boolean;
    atDelivery: boolean;
    shopId?: string;
}

export interface StorePaymentDto {
    type: PaymentType;
    enabled: boolean;
    data: StorePaymentDataDto;
}

export interface StoreDto {
    storeId: string;
    image: ImageDto;
    coverImage: ImageDto;
    name: string;
    storeCategory: StoreCategory;
    address: AddressDto;
    vendorId: string;
    customUrl: string;
    contacts: ContactDto[];
    tags: { name: string }[];
    schedule: ScheduleDayDto[];
    active: boolean;
    currentState: StoreCurrentState;
    storeDeliveries: StoreDeliveryDto[];
    storePayments: StorePaymentDto[];
    subscription: SubscriptionResponse;
    distance: number;
    openForOrders: boolean;
    promos: PromoDto[];
    moderated: boolean;
    fakeOwner?: boolean;
    carouselData?: BannerCarouselItemDto[];
    sellingDescription: string;
    shortDescription: string;
    fullDescription: string;
    advantages: string;
    paymentComment: string;
    deliveryComment: string;
    enabledSpecificDelivery: boolean;
    slogan: string;
}

export interface OptionGroupDtoItem {
    optionId: string;
    name: string;
    price: number;
    selectedByDefault: boolean;
}

export interface OptionGroupDto {
    optionGroupId: string;
    name: string;
    minOptions: number;
    maxOptions: number;
    optionGroupMode: OptionsMode;
    options: OptionGroupDtoItem[];
}

export interface ProductDto {
    version: number;
    name: string;
    description: string;
    price: number;
    unitType: UnitType;
    tags: { name: string }[];
    category: CategoryDto;
    unitQuantity: number;
    outOfStock: boolean;
    promo: {
        promo: boolean;
    };
    hidden: boolean;
    images: ImageDto[];
    productId: string;
    title: string;
    optionGroups: OptionGroupDto[];
    sortOrder: number;
}

export interface ProductOption {
    productId: string;
    name: string;
}

export enum YandexCheckoutApplicationStatus {
    NONE = 'NONE',
    NEW = 'NEW',
    REGISTERED = 'REGISTERED',
    PROCESSED = 'PROCESSED',
}

export interface YandexCheckoutApplicationDto {
    inn: string;
    email: string;
    status: YandexCheckoutApplicationStatus;
}

export interface CategoryDto {
    categoryId: string;
    name: string;
    sortOrder: number;
}

export enum OrderChangeStatusIntent {
    ACCEPT_BY_CUSTOMER,
    ACCEPT_BY_VENDOR,
    DECLINE,
    FINISH_PREPARING,
    FINISH_DELIVERY,
}

export enum PaymentStatus {
    PENDING = 'PENDING',
    WAITING_FOR_CAPTURE = 'WAITING_FOR_CAPTURE',
    CANCELED = 'CANCELED',
    PAID = 'PAID',
}

export enum ShopTypeName {
    ONLINE_SHOP = 'online_shop',
    PRIORITY = 'priority',
}

export interface SubscriptionPlanResponse {
    subscriptionPlanId: string;
    price: number;
    period: string;
    freePeriod: string;
    name: ShopTypeName;
}

export interface SubscriptionResponse {
    active: boolean;
    amount: number;
    issueDate: string;
    renewalDate: string;
    storeId: string;
    subscriptionId: string;
    subscriptionPlan: {
        subscriptionPlanId: string;
        price: number;
        period: string;
        name: ShopTypeName;
        freePeriod: string;
    };
    nextSubscriptionPlanId: string;
}
export interface PhoneLinkResponse {
    storeId: string;
    phone: string;
}

export interface EmailResponse {
    storeId: string;
    orderId: string;
    email: string;
}

export interface PaymentMethodResponse {
    cardLast4: string;
    cardType: string;
    paymentMethodId: string;
}

export enum RedirectAfterPayment {
    SUBSCRIPTION_RESUMED_ONLINE_SHOP = 'subscriptionResumedOnlineShop',
    SUBSCRIPTION_RESUMED_PRIORITY = 'subscriptionResumedPriority',
    PAYMENT_METHOD_CHANGED = 'paymentMethodChanged',
}

export enum RequestStatus {
    NONE = 'NONE',
    PENDING = 'PENDING',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
}

export enum CheckoutStatus {
    NEW = 'NEW',
    ENTER_DETAILS = 'ENTER_DETAILS',
    READY_FOR_ORDER = 'READY_FOR_ORDER',
}

export type DeepPartial<T> = {
    [P in keyof T]?: DeepPartial<T[P]>;
};

export enum SearchItemType {
    PRODUCT = 'PRODUCT',
    STORE = 'STORE',
}

export interface ISearchedItem {
    product?: {
        hidden: boolean;
        name: string;
        outOfStock: boolean;
        price: number;
        productId: string;
        promo: { promo: boolean };
        rank: number;
        storeId: string;
        unitQuantity: number;
        unitType: UnitType;
        images: { imageId: string; defaultImage: boolean; type: SearchItemType }[];
        customUrl: string;
        category: { categoryId: string; name: string };
        isoCode: SupportedCountryCodes;
    };
    store?: {
        creationDate: string;
        customUrl: string;
        distance: number;
        image: { defaultImage: boolean; imageId: string; type: SearchItemType };
        name: string;
        rank: number;
        storeCategory: StoreCategory;
        storeId: number;
        storeState: StoreCurrentState;
        storeDelivery: StoreDeliveryDto[];
        sellingDescription: string;
        slogan?: string;
        address: AddressDto;
    };
    rank: number;
    type: SearchItemType;
}

export interface OrderDeliveryData {
    address: {
        comment: string;
        city: {
            name: string;
        };
        street: {
            name: string;
        };
        geoCoordinates: GeoCoordinatesDto;
    };
    deliveryPrice: number;
}

export interface OrderDelivery {
    data: OrderDeliveryData;
    deliveryType: DeliveryType;
}

export interface AvailableDelivery {
    deliveryPrice: number;
    deliveryType: DeliveryType;
}

export interface CartSelectedPromo {
    promo: PromoDto;
    promoApplied: boolean;
    promoNotification: string;
    discountAmount: number;
}

export interface CartDto {
    cartId: string;
    storeId: string;
    userId: string;
    subtotal: number;
    total: number;
    status: CheckoutStatus;
    availableDeliveries: AvailableDelivery[];
    deliveryInfo: OrderDelivery;
    orderedProducts: OrderResponseProduct[];
    paymentInfo: OrderResponsePaymentInfo;
    selectedPromo?: CartSelectedPromo;
    promoId?: string;
}

export interface ISearchedItems {
    nextStart: string;
    items: ISearchedItem[];
    totalCount: number;
}

export enum SortingDirection {
    ASC = 1,
    DESC = -1,
}

export interface CroppedArea {
    x: number;
    y: number;
    width: number;
    height: number;
}

export enum PaidDeliveryType {
    PER_KM = 'PER_KM',
    STATIC = 'STATIC',
}

export interface PromoConditions {
    minOrderPrice: number;
    deliveryType: PromoDeliveryType;
}

export interface PromoResults {
    discountType: PromoDiscountType;
    discount: number;
    discountedItemId: string;
}

export interface PromoDto {
    promoId: string;
    storeId: string;
    active: boolean;
    image: PromoLabel;
    conditions: PromoConditions;
    results: PromoResults[];
    description: string;
}

export interface BannerCarouselItemDto {
    carouselId: string;
    images: {
        [key: string]: string;
    };
    description: string;
    styles?: CSSProperties;
    url: string;
}

export interface BlogDto {
    heroPost: HeroStoriesDto;
    morePosts: HeroStoriesDto[];
}

export interface HeroStoriesDto {
    title: string;
    date: string;
    slug?: string;
    author?: string;
    coverImage?: string;
    excerpt?: string;
    content?: string;
}

export interface StoreNotificationDto {
    enabled: boolean;
    notificationMethod: NotificationType;
    notificationSettingId: string;
    type: string;
}

export interface MetricsDto {
    storeId: string;
    feedShow: number;
    topFeedShow: number;
    mapFeedShow: number;
    categoryFeedShow: number;
    searchShow: number;
    productSearchShow: number;
    storeEntrance: number;
    calls: number;
}
