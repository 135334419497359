import browserCookies from 'browser-cookies';
import { Iso2To3Map, Iso3To2Map, SupportedCountryCodes } from '../../types';
import { appConfig } from '../../config/app';

export const makeUrl = (
    url: string,
    urlParams: Record<string, any> = {},
    queryParams?: Record<string, any>,
): string => {
    const stringifyQueryParams = queryParams
        ? Object.keys(queryParams).reduce(
              (acc, param) => (queryParams[param] != null ? acc.concat(`${param}=${queryParams[param]}&`) : acc),
              '?',
          )
        : '';
    return Object.keys(urlParams)
        .sort((a, b) => b.length - a.length)
        .reduce((acc, k) => acc.replace(':' + k, urlParams[k]), url)
        .concat(stringifyQueryParams);
};

export const range = (a: number, b: number) => {
    return Array(b - a + 1)
        .fill(null)
        .map((_, i) => i + a);
};

export const hasDataChanged = <T>(previousData: T, currentData: T): boolean => {
    return JSON.stringify(previousData) !== JSON.stringify(currentData);
};

export const getOrientation = (): string | number => {
    return (
        (screen.orientation || {}).type ||
        (screen as any).mozOrientation ||
        (screen as any).msOrientation ||
        window.orientation
    );
};

export const splitOnce = (str: string, delimiter: string): [string, string] => {
    const i = str.indexOf(delimiter);
    if (i === -1) {
        return [str, ''];
    }
    return [str.slice(0, i), str.slice(i + delimiter.length)];
};

export const selectAllTextInInputById = (id: string): void => {
    const input: HTMLInputElement = document.getElementById(id) as HTMLInputElement;
    input.focus();
    input.select();
};

export const transliterationForUrl = (str: string): string => {
    const ru = new Map([
        ['а', 'a'],
        ['б', 'b'],
        ['в', 'v'],
        ['г', 'g'],
        ['д', 'd'],
        ['е', 'e'],
        ['є', 'e'],
        ['ё', 'e'],
        ['ж', 'j'],
        ['з', 'z'],
        ['и', 'i'],
        ['ї', 'yi'],
        ['й', 'i'],
        ['к', 'k'],
        ['л', 'l'],
        ['м', 'm'],
        ['н', 'n'],
        ['о', 'o'],
        ['п', 'p'],
        ['р', 'r'],
        ['с', 's'],
        ['т', 't'],
        ['у', 'u'],
        ['ф', 'f'],
        ['х', 'h'],
        ['ц', 'c'],
        ['ч', 'ch'],
        ['ш', 'sh'],
        ['щ', 'shch'],
        ['ы', 'y'],
        ['э', 'e'],
        ['ю', 'u'],
        ['я', 'ya'],
    ]);

    str = str.replace(/["<> /,.:;\[\]|§±`~?!{}ъьЪЬ()$%^&*@#\\=+_'№]+/g, '');

    return Array.from(str).reduce(
        (s, l) =>
            s +
            (ru.get(l) ||
                (ru.get(l.toLowerCase()) === undefined && l) ||
                ru.get(l.toLowerCase()).charAt(0).toUpperCase() + ru.get(l.toLowerCase()).slice(1)),
        '',
    );
};

function getCurrency(isoCode?: SupportedCountryCodes) {
    const country: SupportedCountryCodes = isoCode
        ? isoCode
        : typeof window === 'undefined'
        ? null
        : (browserCookies.get('country') as SupportedCountryCodes);
    switch (country) {
        case SupportedCountryCodes.UZB:
            return 'UZS';
        case SupportedCountryCodes.SRB:
            return 'RSD';
        case SupportedCountryCodes.NLD:
            return 'EUR';
        case SupportedCountryCodes.ITA:
            return 'EUR';
        case SupportedCountryCodes.GBR:
            return 'GBP';
        case SupportedCountryCodes.USA:
            return 'USD';
        case SupportedCountryCodes.RUS:
            return 'RUB';
        default:
            return 'RUB';
    }
}


function getCurrencySign(isoCode?: SupportedCountryCodes) {
    const country: SupportedCountryCodes = isoCode
        ? isoCode
        : typeof window === 'undefined'
        ? null
        : (browserCookies.get('country') as SupportedCountryCodes);
    switch (country) {
        case SupportedCountryCodes.UZB:
            return 'сўм';
        case SupportedCountryCodes.SRB:
            return 'din.';
        case SupportedCountryCodes.NLD:
            return '€';
        case SupportedCountryCodes.ITA:
            return '€';
        case SupportedCountryCodes.GBR:
            return '£';
        case SupportedCountryCodes.USA:
            return '$';
        case SupportedCountryCodes.RUS:
            return '₽';
        default:
            return '₽';
    }
}

export function getCountryCode() {
    const country: SupportedCountryCodes =
        typeof window === 'undefined' ? null : (browserCookies.get('country') as SupportedCountryCodes);

    switch (country ?? appConfig.getRegions[0]) {
        case SupportedCountryCodes.UZB:
            return 'uz';
        case SupportedCountryCodes.SRB:
            return 'rs';
        case SupportedCountryCodes.NLD:
            return 'nl';
        case SupportedCountryCodes.ITA:
            return 'it';
        case SupportedCountryCodes.GBR:
            return 'gb';
        case SupportedCountryCodes.USA:
            return 'us';
        case SupportedCountryCodes.RUS:
            return 'ru';
    }
}

export const getCurrencyText = (price?: number | string, isoCode?: SupportedCountryCodes) => {
    return price ? `${price} ${getCurrencySign(isoCode)}` : getCurrencySign(isoCode);
};

export const languageCodeOnly = (fullyQualifiedCode: string) => {
    return fullyQualifiedCode ? fullyQualifiedCode.split('-')[0] : '';
};

export const formatCurrency = (value: number, isoCode = SupportedCountryCodes.RUS) => {
    return (isNaN(value) || value === null) ? getCurrencySign(isoCode) : value.toLocaleString(isoCode || SupportedCountryCodes.RUS, { style: 'currency', currency: getCurrency(isoCode) });
};

export const subscriptionPrice = (isoCode?: SupportedCountryCodes) => {
    const country: SupportedCountryCodes = isoCode
        ? isoCode
        : typeof window === 'undefined'
        ? null
        : (browserCookies.get('country') as SupportedCountryCodes);
    switch (country) {
        case SupportedCountryCodes.UZB:
            return '520000 сўм/мес';
        case SupportedCountryCodes.SRB:
            return '4700 din. per month';
        case SupportedCountryCodes.NLD:
            return '40€ per month';
        case SupportedCountryCodes.GBR:
            return '30£ per month';
        case SupportedCountryCodes.USA:
            return '40$ per month';
        case SupportedCountryCodes.ITA:
            return '40€ per month';
        case SupportedCountryCodes.RUS:
            return '950 ₽/мес';
        default:
            return '950 ₽/мес';
    }
};

export const promoPrice = (isoCode?: SupportedCountryCodes) => {
    const country: SupportedCountryCodes = isoCode
        ? isoCode
        : typeof window === 'undefined'
        ? null
        : (browserCookies.get('country') as SupportedCountryCodes);
    switch (country) {
        case SupportedCountryCodes.UZB:
            return '130000 сўм/мес';
        case SupportedCountryCodes.SRB:
            return '1200 din. per month';
        case SupportedCountryCodes.NLD:
            return '10€ per month';
        case SupportedCountryCodes.GBR:
            return '8£ per month';
        case SupportedCountryCodes.USA:
            return '10$ per month';
        case SupportedCountryCodes.ITA:
            return '10€ per month';
        case SupportedCountryCodes.RUS:
            return '300 ₽/мес';
        default:
            return '300 ₽/мес';
    }
};

export const countryPriceByMonth = (isoCode?: SupportedCountryCodes) => {
    const country: SupportedCountryCodes = isoCode
        ? isoCode
        : typeof window === 'undefined'
        ? null
        : (browserCookies.get('country') as SupportedCountryCodes);
    switch (country) {
        case SupportedCountryCodes.UZB:
            return 'сўм/мес';
        case SupportedCountryCodes.SRB:
            return 'din./month';
        case SupportedCountryCodes.NLD:
            return '€/month';
        case SupportedCountryCodes.GBR:
            return '£/month';
        case SupportedCountryCodes.USA:
            return '$/month';
        case SupportedCountryCodes.ITA:
            return '€/month';
        case SupportedCountryCodes.RUS:
            return '₽/мес';
        default:
            return '₽/мес';
    }
};

export const validateCountryCode = (country: SupportedCountryCodes) => {
    switch (country) {
        case SupportedCountryCodes.UZB:
            return SupportedCountryCodes.UZB;
        case SupportedCountryCodes.SRB:
            return SupportedCountryCodes.SRB;
        case SupportedCountryCodes.NLD:
            return SupportedCountryCodes.NLD;
        case SupportedCountryCodes.GBR:
            return SupportedCountryCodes.GBR;
        case SupportedCountryCodes.USA:
            return SupportedCountryCodes.USA;
        case SupportedCountryCodes.ITA:
            return SupportedCountryCodes.ITA;
        case SupportedCountryCodes.RUS:
            return SupportedCountryCodes.RUS;
        default:
            return SupportedCountryCodes.RUS;
    }
};
export const getSeoTextForProduct = (
    country: SupportedCountryCodes,
    storeName: string,
    productName: string,
    city: string,
): { title: string; description: string } => {
    switch (country) {
        case SupportedCountryCodes.UZB:
        case SupportedCountryCodes.SRB:
        case SupportedCountryCodes.NLD:
        case SupportedCountryCodes.GBR:
        case SupportedCountryCodes.ITA:
        case SupportedCountryCodes.USA:
            return {
                title: `${productName} at a good price in the ${storeName}`,
                description: `${productName} at a good price at ${city} in the ${storeName}`,
            };
        case SupportedCountryCodes.RUS:
        default:
            return {
                title: `${productName} по выгодной цене ${storeName}`,
                description: `${productName} по низкой цене в ${city} в ${storeName}`,
            };
    }
};

export const getSeoTextForStore = (
    country: SupportedCountryCodes,
    storeName: string,
    storeCategory: string,
    address: string,
    phone: string,
): string => {
    switch (country) {
        case SupportedCountryCodes.UZB:
        case SupportedCountryCodes.SRB:
        case SupportedCountryCodes.NLD:
        case SupportedCountryCodes.GBR:
        case SupportedCountryCodes.ITA:
        case SupportedCountryCodes.USA:
            return `${storeName} in the ${storeCategory} category, address ${address}, phone ${phone}. Detailed prices, delivery rules and order descriptions.`;
        case SupportedCountryCodes.RUS:
        default:
            return `${storeName} в категории ${storeCategory}, адрес ${address}, телефон ${phone}. Подробные цены, описания доставки и заказа.`;
    }
};

export const isSubdomain = () => {
    return process.env.NEXT_PUBLIC_DOMAINS_WITH_BUSINESS_LIST?.split(',').includes(process.env.NEXT_PUBLIC_DOMAIN_LOCATION)
}

export const getSubdomainPrefix = (isoCode: SupportedCountryCodes) => {
    return Iso3To2Map[isoCode]?.toLowerCase();
}
export const getClientUrl = (isoCode?: SupportedCountryCodes) => {
    if (process.env.NEXT_PUBLIC_IS_DEV === '1' || isSubdomain()) {
        return process.env.NEXT_PUBLIC_CLIENT_URL;
    }
    if (isoCode) {
        const prefix = getSubdomainPrefix(isoCode);
        return `https://${prefix}.any.market`;
    }
    const country = typeof window !== 'undefined' ? browserCookies.get('country') : undefined;
    const prefix = country ? getSubdomainPrefix(country as SupportedCountryCodes) : 'ru';
    return `https://${prefix}.any.market`;
}

type GetCloudinaryImageSrc = {
    src: string;
    width: number;
    quality?: number;
    autoFormat?: boolean;
};
export const getCloudinaryImageSrc = ({ src, width, quality, autoFormat = false }: GetCloudinaryImageSrc) => {
    const params: string[] = [`w_${width}`];
    if (quality) {
        params.push(`q_${quality}`);
    }
    if (autoFormat) {
        params.push(`f_auto`);
    }

    return `https://res.cloudinary.com/${process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}/image/upload/${params.join(',')}/${src}`;
};