export const appConfig = {
    get googleApiKey(): string {
        return process.env.REACT_APP_GOOGLE_API_TOKEN || process.env.NEXT_PUBLIC_GOOGLE_API_TOKEN || '';
    },

    get marketplacePhoneNumber(): string {
        return '4407738227606';
    },

    get notTrimmedMarketplacePhoneNumber(): string {
        return '+44(0)7738227606';
    },

    get marketplacePhoneNumberRu(): string {
        return '79168255053';
    },

    get notTrimmedMarketplacePhoneNumberRu(): string {
        return '+7 916 825-50-53';
    },

    get showSuccessOrderMessageTime(): number {
        return 15;
    },

    get defaultCoordinates(): { lat: number; lon: number } {
        return {
            lat: 55.6558,
            lon: 37.5173,
        };
    },

    get defaultCityName(): string {
        return 'Москва';
    },

    get reservedUrlNames(): string[] {
        return [
            'about',
            'registration',
            'vendor',
            'login',
            'onboarding',
            'location',
            'checkout',
            'legal',
            'reset-password',
            'oy8aw',
            'an1fu',
            'adminreport',
        ];
    },

    get otpTimeout(): number {
        if (process.env.REACT_APP_OTP_TIMEOUT) {
            return Number(process.env.REACT_APP_OTP_TIMEOUT);
        }
        return process.env.NODE_ENV === 'development' ? 10 : 30;
    },

    get orderRefreshInterval(): number {
        return process.env.NODE_ENV === 'development' ? 40e3 : 60e3;
    },

    get defaultLocations(): Record<string, { lat: number; lon: number; qc_geo: number; address: string, region: string }> {
        return {
            RUS: {
                lat: 55.75396,
                lon: 37.620393,
                qc_geo: 4,
                address: 'г Москва',
                region: 'г Москва',
            },
            GBR: {
                lat: 51.51007354,
                lon: -0.1349485,
                qc_geo: 4,
                address: 'London',
                region: 'England',
            },
            SRB: {
                lat: 44.81650141,
                lon: 20.46057316,
                qc_geo: 4,
                address: 'Belgrade',
                region: 'null',
            },
            UZB: {
                lat: 41.26465,
                lon: 69.21627,
                qc_geo: 4,
                address: 'Тоshkent',
                region: 'г Ташкент',
            },
            NLD: {
                lat: 52.3657028,
                lon: 4.955106799999999,
                qc_geo: 4,
                address: 'Amsterdam',
                region: 'Noord-Holland',
            },
            ITA: {
                lat: 45.470956,
                lon: 9.201469399999999,
                qc_geo: 4,
                address: 'Milano',
                region: 'Lombardia',
            },
            USA: {
                lat: 38.9016153,
                lon: -77.0253446,
                qc_geo: 4,
                address: 'Washington',
                region: 'District of Columbia',
            },
        };
    },

    get getLanguages(): Array<{ code: string; label: string }> {
        return [
            {
                code: 'ru',
                label: 'Русский - RU',
            },
            {
                code: 'en',
                label: 'English - EN',
            },
            {
                code: 'it',
                label: 'Italiano - IT',
            },
            {
                code: 'nl',
                label: 'Netherlands - EN',
            },
            {
                code: 'rs',
                label: 'Српски - RS',
            },
            {
                code: 'uz',
                label: 'O\'zbek - UZ'
            }
        ];
    },

    get getRegions(): Array<string> {
        return ['USA', 'RUS', 'ITA', 'GBR', 'NLD', 'SRB', 'UZB']; // first region is default value
    },
};
